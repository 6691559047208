@import "assets/scss/_variables.scss";

.country-list {
    display: flex;
    flex-direction: column;
    height: 200px; // = 5 * (23 + 17)
    gap: 23px;
    overflow-y: scroll;

    .country-element {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .country-key {
            font-weight: 600;
            font-size: 13px;
            line-height: 17px;
        }

        .country-value {
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: $primary;
            padding-right: 26px;
        }
    }
}

/* width */
::-webkit-scrollbar {
    width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
    background: white;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba($main-texts, 0.3);
    transition: background 300ms ease;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba($main-texts, 0.5);
}