@import "assets/scss/_variables";

.subsection-number {
    color: $primary;
    font-size: 1.275rem;
}
.page-subsection {
    border-radius: 30px;
    padding: 5px 10px;
    color: $clear-blue-grey;
    background-color: $light-blue-grey;
    font-weight: 600;
}

.back-svg {
    width: 30px !important;
    height: 30px !important;
    fill: $black;
    &:hover,
    &:focus {
        fill: $primary;
    }
}
