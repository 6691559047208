@import 'assets/scss/_variables.scss';

//FONT COLOR
$colors-map: (
    "white": $white,
    "black": $black,
    "primary": $primary,
    "secondary": $secondary,
    "error": $error,
    "silver": $silver,
    "light-grey": $light-grey,
    "semi-light-grey": $semi-light-grey,
    "clear-blue-grey": $clear-blue-grey,
    "clear-text": $clear-text,
    "success": $success,
);

//COLORS
@mixin colors {
    @each $color, $attr in $colors-map {
        &-#{$color} {
            color: $attr!important;
        }
    }
}

.color {
    @include colors;
}

//FONT WEIGHT
$weight-map: (
    "bold": bold,
);

@mixin texts {
    @each $weight, $attr in $weight-map {
        &-#{$weight} {
            font-weight: $attr;
        }
    }
}

.text {
    @include texts;
}

//MARGIN RIGHT
$margins-right-map: (
    1: 1rem,
    2: 1.5rem,
);

@mixin margins-right {
    @each $mr, $attr in $margins-right-map {
        &-#{$mr} {
            margin-right: $attr;
        }
    }
}

.mr {
    @include margins-right;
}

//MARGIN LEFT
$margins-left-map: (
    0: 0,
    1: 1rem,
    2: 1.5rem,
);

@mixin margins-left {
    @each $ml, $attr in $margins-left-map {
        &-#{$ml} {
            margin-left: $attr;
        }
    }
}

.ml {
    @include margins-left;
}

//BORDER
.border-bottom-none {
    border-bottom: none !important;
}

//PADDING

//PADDING LEFT
$paddings-left-map: (
    0: 0,
    1: 1rem,
    2: 1.5rem,
);

@mixin paddings-left {
    @each $pl, $attr in $paddings-left-map {
        &-#{$pl} {
            padding-left: $attr!important;
        }
    }
}

.pl {
    @include paddings-left;
}
