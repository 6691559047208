@import 'assets/scss/_variables.scss';

.avatar {
    border-radius: 50% !important;
    height: 55px !important;
    width: 55px !important;
    background-size: 20px;
    background-color: #AEEACF;
    border: 1px solid #EFEFEF;

    .avatar-content {
        font-size: 18px;
        color: $white;
    }
}
