@import 'assets/scss/_variables';

// Page Layout
.page-container {
    position: absolute;
    top: $header-height;
    left: $sidebar-width;
    width: calc(100% - #{$sidebar-width});
    min-height: calc(100% - #{$header-height});

    .middle-container {
        padding: 2%;
        border-radius: 15px;
        border: 1px solid #f0f2f4;
        
        &.shadow-none {
            box-shadow: none;
        }

        &.bordered {
            border: 2px solid $light-blue-grey;
        }
    }

    // For client-invoices page, we specify a different top because there are 2 position: sticky
    .client-invoices thead {
        top: 140px;
    }
}

// Footer
footer {
    display: flex;
    height: $footer-height;
    font-size: 0.875rem;
    justify-content: flex-end;
    align-items: flex-end;
    opacity: 0.3;
}

// Header
.header {
    position: sticky;
    top: 0;
    padding: 0 3%;
    left: $sidebar-width;
    z-index: 100;
    width: calc(100% - #{$sidebar-width});
    height: $header-height;
    color: $main-texts;
    background-color: $white;
}

// Side bar
.sidebar-container {
    position: fixed;
    z-index: 100;
    width: $sidebar-width;
    height: 100%;
    padding-left: 35px;

    .wis-logo-container {
        width: 90%;
        padding: 25px 15px 15px 17px;
    }

    .nav {
        ul {
            padding: 0%;
            width: 100%;

            li {
                margin-top: 1px;
                height: 60px;
                position: relative;

                a {
                    display: flex !important;
                    align-items: center;
                    height: 100%;
                    padding: 0 0 0 26px;
                    font-size: 0.875rem;
                    line-height: 3.5rem;
                    width: 100%;

                    &:link {
                        text-decoration: none;
                    }

                    span {
                        font-weight: 600 !important;
                        margin-left: 35px;
                    }

                    &:hover {
                        border-radius: 20px;
                        background-color: $light-blue-grey;
                    }

                    &.active {
                        border-radius: 20px;
                        background-color: $light-blue-grey;

                        & > span {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
}

a[class^='icon-'] {
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 18px;
        transform: translateY(-50%);
        background-size: contain;
        width: 26px;
        height: 26px;
        display: block;
    }

    &[class*='dashboard'] {
        &::before {
            background: transparent url('../icons/menu/icon-dashboard.svg') no-repeat center;
        }

        &.active::before {
            background: transparent url('../icons/menu/icon-dashboard-active.svg') no-repeat center;
        }
    }

    &[class*='clients'] {
        &::before {
            background: transparent url('../icons/menu/icon-client-management.svg') no-repeat center;
        }

        &.active::before {
            background: transparent url('../icons/menu/icon-client-management-active.svg') no-repeat center;
        }
    }

    &[class*='optin'] {
        &::before {
            background: transparent url('../icons/menu/icon-optin-export.svg') no-repeat center;
        }

        &.active::before {
            background: transparent url('../icons/menu/icon-optin-export-active.svg') no-repeat center;
        }
    }

    &[class*='spam'] {
        &::before {
            background: transparent url('../icons/menu/icon-spam-list.svg') no-repeat center;
        }

        &.active::before {
            background: transparent url('../icons/menu/icon-spam-list-active.svg') no-repeat center;
        }
    }
}
