@import 'assets/scss/_variables.scss';

.heatmap-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;

    #tooltip {
        background: $white;
        border: 1px solid $clear-blue-grey;
        padding: 5px;
        font-size: 14px;
    }

    .heatmap-svg {
        width: 100%;
        height: 100%;
        overflow: visible;
    }

    .heatmap-calendar-label {
        font-size: 12px;
        color: $clear-blue-grey;
    }

    .heatmap-legend {
        width: 100%;
        display: flex;
        align-items: center;
    }
}

.custom-tooltip {
    background: rgba($white, 0.95);

    padding: 20px;
    border: 1px solid $light-grey;
    border-radius: 10px;

    .custom-tooltip__label {
        font-size: 16px;
        font-weight: 500;
    }

    .custom-tooltip__element {
        font-weight: 300;
    }

    .custom-tooltip__total {
        font-weight: 500;
    }
}

.piechart-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
}

.percentage-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: scroll;
}

.percentage-label {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 8px;
    padding-right: 15px;
}

.piechart-text {
    font-size: 14px;
}

.percentage-name-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.percentage-icon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.percentage-name {
    font-weight: 400;
}
