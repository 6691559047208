@import "assets/scss/_variables.scss";

// Tables
.table {
  position: relative;
  border-spacing: 0;
  font-size: 0.875rem;
  overflow-x: auto !important;
  width: 100% !important;
  font-size: $size-13;
  border-collapse: inherit !important;

  tbody {
    tr {
      min-height: 60px;
    }

    tr:hover {
      background: $row-selected;
    }
  }

  thead {
    position: sticky;
    top: 170px; // header-size + filter
    z-index: 1;
    background-color: white;

    tr {
      th {
        font-size: $size-13;
        font-weight: 500;
        color: $main-texts;
        border-bottom: none;

        button {
          font-weight: bold;
          padding: 0;
          height: 100%;
        }
      }
    }
  }

  td,
  th {
    line-height: 1;
    height: 50px;
    vertical-align: middle;
    border-bottom: 1px solid #eff1f3;
    position: relative;
  }

  th {
    text-align: left;

    &[align="center"] {
      text-align: center;
    }

    &[align="right"] {
      text-align: right;
    }
  }

  input[type="checkbox"] {
    width: 18px;
    height: 18px;
    margin: 0;
    vertical-align: middle;
  }

  td {
    &.bold {
      font-weight: bold;
    }
  }
}

// Table padding large
.table-p-lg {

  th,
  td {
    padding: 0 30px 0 47px;
  }
}

.table-data {
  max-width: 100%;
  table-layout: fixed;

  &.table-data-full {
    max-width: 100%;

    th {
      width: 15vw !important;

      &.th-small {
        width: 8vw !important;
      }
    }
  }

  tbody tr:hover {
    background: none;
  }

  th,
  td {
    min-height: 50px;
    line-height: 1.1875rem;
  }

  th {
    padding: 0;
  }

  td {
    padding: 0;
  }

  tr:last-child {

    th,
    td {
      border: 0;
    }
  }

  +.table-data {
    margin-left: 0;

    @media screen and (min-width: $screen-lg) {
      margin-left: 20px;
    }
  }
}

.table-cell {
  line-height: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table-link {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 1.125rem;
  top: 0;
  left: 0;

  &:link,
  &:visited {
    text-decoration: none;
    color: inherit;
  }
}

td[align="center"] {
  .table-link {
    justify-content: center;
  }
}

td[align="right"] {
  .table-link {
    justify-content: flex-end;
  }
}

.table-empty-val {
  opacity: 0.3;
}

.table-striped {
  tbody tr:hover {
    background: none;
  }

  tbody tr:nth-of-type(even) {
    background: #f9f9f9;
  }
}

// Clickable row
.clickable-row {
  cursor: pointer;
}

// hr
.table-hr {
  border-top: 1px solid #eff1f3;
  margin: 30px auto;
  max-width: 720px;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

// Layout
.table-layout-fixed {
  table-layout: fixed;
}

// Row checked
.table tbody tr.row-checked {
  background: $row-selected;
}

// Table arrow filter
th button {
  background: none;
  vertical-align: middle;

  svg {
    width: 12px;
    height: 12px;
    margin-left: 15px;
    vertical-align: inherit;
    opacity: 0.5;
    transition: transform 0.2s ease, opacity 0.3s ease;
  }

  &.active {
    svg {
      opacity: 1;
    }
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }

  &.desc {
    svg {
      transform: rotate(180deg);
    }
  }
}

// Animation
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}