@import "assets/scss/_variables.scss";

.client-management-container {
  .client-management-export {
    position: relative;
    z-index: 4;
    background-color: white;
  }
  .client-management-filter {
    position: relative;
    z-index: 2;
    background-color: white;
  }
}