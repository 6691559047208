@import 'assets/scss/_variables.scss';

$gap: 25px;

.dashboard-filter {
    position: sticky;
    top: 145px;
    z-index: 2;
}

.dashboard-layout {
    display: flex;
    flex-direction: column;
    gap: $gap;
}

.dashboard-container {
    display: flex;
    flex-direction: column;
    gap: $gap;
}

.dashboard-section {
    display: flex;
    flex-direction: column;
    gap: $gap;

    .dashboard-section-title {
        font-weight: 500;
        margin: 0;
        margin-bottom: 10px;
    }
}

.dashboard-content {
    .dashboard-content-title {
        color: $black;
        font-size: 22px;
        margin: 0;
    }
    .dashbord-content-total {
        color: $primary;
        font-weight: 600;
        font-size: $size-16;
    }
}

.dashboard-section-container {
    display: flex;
    flex-direction: column;
    padding: 25px 30px 30px;
    gap: 24px;
    border: 2px solid #f0f2f4;
    border-radius: 20px;
    flex-grow: 1;
    width: 100%;

    h3 {
        font-weight: 500;
        font-size: 22px;
        line-height: 33px;
    }
}

.dashboard-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    gap: $gap;
}

.dashboard-consumption-piechart {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: 260px;
    width: 100%;

    h4 {
        margin: 0;
        padding-left: 30px;
        padding-top: 20px;
        font-weight: 500;
        font-size: 22px;
        line-height: 33px;
    }
}

.no-data {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

.card-container {
    background: $white;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    min-height: 100px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--border {
        border: 2px solid $light-grey;
    }

    &--icon {
        border: 2px solid $light-grey;
        flex-direction: row;
        &.active {
            transition: 300ms ease-in-out;
            background-color: $light-grey;
        }
    }

    .icon,
    .content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon {
        flex: 1;
        align-items: center;
    }

    .content {
        flex: 1;
        align-items: start;
        flex-direction: column;

        &--large {
            flex: 2;
        }
    }

    .title {
        font-weight: 500;
        font-size: 15px;
    }

    .data {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        h3 {
            display: block;
            font-weight: 600;
            font-size: 40px;
            line-height: 40px;
            margin: 0;
        }

        span {
            font-weight: 300;
            font-size: 15px;
        }
    }
}

.dashboard-form-filter {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: #f8f9fa;
    border-radius: 20px;
    padding: 10px 30px;
    gap: 32px;

    .form-filter-title {
        font-size: $size-16;
        color: $clear-blue-grey;
    }

    .form-fields {
        display: flex;
        gap: 32px;
    }

    .form-actions {
        display: inline-flex;
        align-items: flex-end;
        gap: 16px;
    }

    input[type='date'] {
        border: 1px solid $clear-grey;
        padding-left: 10px;
        height: 38px;
        padding-right: 10px;
        color: $clear-blue-grey;
        border-radius: 5px;
    }
}

.dashboard-section-filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;

    .form-fields {
        display: flex;
        gap: 32px;
    }

    .form-actions {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    input[type='date'] {
        border: 1px solid $clear-grey;
        padding-left: 10px;
        height: 38px;
        padding-right: 10px;
        color: $clear-blue-grey;
        border-radius: 5px;
    }
}

.dashboard-service-efficiency {
    display: flex;
    flex-direction: column;

    .dashboard-service-efficiency__button {
        border-radius: 30px;
        cursor: pointer;
        display: block;
        width: 120px;
        text-align: center;
        padding: 10px 10px;
        background: $primary;
        color: white;
        text-decoration: none;
        &:hover {
            color: white !important;
            background: $gradient-main;
        }
    }

    .dashboard-service-efficiency__group {
        display: grid;
        align-items: center;
        width: 50%;
        gap: 15px;
        grid-template-columns: 1fr 2fr 2fr;
        align-items: center;
        color: $black;
        font-size: $size-14;
        position: relative;

        .tooltip {
            position: absolute;
            width: 200px;
            height: 200px;
            z-index: 2;
            background-color: red;
        }
    }
}
