@import 'assets/scss/_variables.scss';

.toast-container {
    position: fixed;
    height: 100%;
    overflow: hidden;
    top: 0;
    right: 1rem;
    z-index: 100;
    padding: 2rem;
    color: $clear-blue-grey;
    width: 250px !important;

    .wis-toast {
        width: 100%;
        transition: animation 500ms ease-in-out;
        animation: fadeIn 500ms;
        padding: 1rem 1rem;
        border-radius: 20px;
        background-color: $light-blue-grey;
        box-shadow: -1px 7px 24px -7px rgba(107, 120, 151, 0.8);
        display: flex;
        align-items: center;

        span {
            font-size: $size-14;
        }

        svg {
            height: 30px !important;
            width: 30px !important;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
