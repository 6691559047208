@import "assets/scss/_variables.scss";

.client-list-header-no-collapse {
  height: 80px;
  background-color: $light-blue-grey;
  border-radius: 20px;
  color: $clear-blue-grey;
  padding: 0px 35px 0px 35px;
  h2 {
    font-size: $size-22 !important;
  }
  div > svg {
    fill: $clear-blue-grey;
  }

  .select-container {
    min-width: 180px;
  }
}

.client-list-header-collapse-container {
  position: relative;

  h2 {
    font-size: $size-22 !important;
  }

  .client-list-header-collapse {
    position: relative;
    padding: 1rem 2rem;
    width: 100%;
    min-height: 20px;
    background-color: $light-blue-grey;
    border-radius: 20px;
    color: $clear-blue-grey;
    &:before {
      content: "";
    }

    .client-list-header-collapse-header {
      cursor: pointer;
    }

    span {
      color: $clear-blue-grey;
    }

    svg.collapse-arrow-button {
      fill: $clear-blue-grey;
    }

    &:hover,
    &.is-open {
      h2 {
        transition: color 300ms ease-in-out;
        color: $primary;
      }
      .collapse-arrow-button {
        fill: $primary;
      }
    }

    &.is-open {
      .select-container {
        z-index: 50;
      }
    }

    .collapse-header {
      cursor: pointer;
      height: 80px;
    }

    .collapse-arrow-button {
      height: 20px;
      width: 20px;
      transform: rotate(90deg);
      transition: all 300ms linear;
      > svg {
        fill: $clear-blue-grey;
      }
      &.active {
        transform: rotate(270deg);
        > svg {
          fill: $primary;
        }
      }
    }

    .client-list-header-content {
      padding: 0 3rem 0 0;
      height: 0;
      overflow: hidden;
      transition: max-height 300ms cubic-bezier(0, 0.6, 0.6, 1);
    }
  }
}

.client-list-form-export {
  background-color: $light-blue-grey;
  border-radius: 20px;
  color: $clear-blue-grey;
  padding: 30px;
  width: 100%;

  .client-list-form-export-container {
    position: relative;
    padding: 0rem 3rem 0rem 0rem;
    width: 100%;
  }
}
