@import "assets/scss/_variables.scss";

input[type="text"],
input[type="email"] {
    font-size: $size-13;
}

input::-webkit-input-placeholder {
    font-size: small;
    opacity: 0.5 !important;
    color: $clear-blue-grey !important;
}
input:focus::-webkit-input-placeholder {
    opacity: 1 !important;
}

label {
    font-weight: 600;
    &.is-invalid {
        color: red !important;
    }
    sup {
        color: $primary;
        &.is-invalid {
            color: red !important;
        }
    }
    &.btn-main {
        padding: 7px 12px 7px 12px;
        border-radius: 20px !important;
        border: none;
        font-size: $size-13 !important;
        min-width: 140px;
        color: $white;
        background-color: $primary;
        border-color: $primary;

        &:hover {
            cursor: pointer;
            background: $gradient-main;
        }
    }
}

.form-group {
    display: flex;
    flex-direction: column;

    i {
        color: red;
    }
}

.form-select-submit-field {    
    &.action {
        display: flex;
        flex-direction: row;
    }
    &.select {
        max-width: 256px;
    }
}

.input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px;
    gap: 0 10px;
}

.input-prefix {
    font-weight: 600;
    font-size: 18px;
}

.input-text {
    border: 1px solid $clear-grey !important;
    padding: 11px 13px 11px 44px !important;

    &.rounded {
        border-radius: 20px !important;
    }

    &.is-invalid {
        color: red !important;
        &:focus {
            border: 1px solid red !important;
        }
        border: 1px solid red !important;
    }

    &:focus {
        border: 1px solid $clear-blue-grey !important;
    }

    &.input-filled {
        border: 1px solid $clear-blue-grey !important;
    }
}

//input icons (login, password)
div[class^="icon-"] {
    position: relative;

    &::before {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        content: "";
        background-size: contain;
        width: 20px;
        height: 20px;
        display: block;
    }
    &[class*="login"] {
        &::before {
            background: transparent url("../../assets/icons/icon-user.svg") no-repeat center;
        }
        &.active::before {
            background: transparent url("../../assets/icons/icon-user-active.svg") no-repeat center;
        }
        &.is-invalid::before {
            background: transparent url("../../assets/icons/icon-user-invalid.svg") no-repeat center;
        }
    }
    &[class*="password"] {
        &::before {
            background: transparent url("../../assets/icons/icon-password.svg") no-repeat center;
        }
        &.active::before {
            background: transparent url("../../assets/icons/icon-password-active.svg") no-repeat center;
        }
        &.is-invalid::before {
            background: transparent url("../../assets/icons/icon-password-invalid.svg") no-repeat center;
        }
    }
}

// show password icon
.show-password {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}
