// Pagination
@import 'assets/scss/_variables.scss';

.pagination {
    width: 100%;
    padding: 20px 20px 0;
    font-size: 0.875rem;

    &-select {
        font-size: 0.875rem;
        position: relative;

        select {
            border: 0;
            margin: 0 40px 0 15px;

            &:focus {
                outline: 0;
            }
        }
    }
}

.pagination-next,
.pagination-prev {
    line-height: 0;
    width: 20px;
    height: 20px;
    background: none;
    margin: 0;
    padding: 0;
    text-align: right;
    height: 20px!important;
    border-radius: inherit;
    box-shadow: none!important;
    padding: 0!important;
    min-width: 20px!important;
    svg {
        vertical-align: middle;
        max-width: 100%;
    }

    &:disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.5;
    }

    &:not(:disabled):hover {
        svg {
            fill: $grey;
        }
    }

    &:focus {
        outline: 0;
    }
}

.pagination-prev {
    transform: rotate(180deg);
    margin-left: 30px;
    margin-right: 30px;
}
