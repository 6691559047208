@import 'assets/scss/_variables.scss';

.wis-card {
    padding: 1rem;
    width: 100%;
    &[card-type='bordered'] {
        border: 2px solid $light-grey;
    }
    &[card-type='thin-bordered'] {
        border: 1px solid #f0f2f4;
    }
    &[card-type='shadowed'] {
        box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.05);
    }
    &[card-radius='squarred'] {
        border-radius: 3px;
    }
    &[card-radius='rounded'] {
        border-radius: 15px;
    }
}
