@import "assets/scss/_variables.scss";

button {
  padding: 7px 12px 7px 12px;
  max-height: 35px;
  border-radius: 20px !important;
  border: none;
  font-size: $size-13 !important;

  svg {
    width: 14px;
    height: 14px;
  }

  &.bordered {
    border: 1px solid white;
  }

  &:disabled {
    box-shadow: none !important;
    opacity: 0.2;
  }

  &:focus {
    outline: 0 !important;
  }

  &.btn-main {
    min-width: 120px;
    color: $white;
    background-color: $primary;
    border-color: $primary;

    &:hover {
      background: $gradient-main;
      svg {
        fill: $white;
      }
    }

    &:disabled {
      opacity: 0.2;
      background: $primary;
    }
    i {
      padding-right: 10px;
      svg {
        fill: $white;
      }
    }
  }

  &.btn-main-inverted {
    min-width: 120px;
    color: $white;
    background: $gradient-main;
    i {
      padding-right: 10px;
      svg {
        fill: $white;
      }
    }
    &:hover {
      background: none;
      background-color: $primary !important;
    }
    &:disabled {
      background-color: $primary !important;
      opacity: 0.2;
    }
  }

  &.btn-cta {
    min-width: 120px;
    color: $primary;
    background: $white;
    border: 1px solid $light-grey;
    box-shadow: none !important;

    &:hover {
      border: 1px solid $white;
      color: $white;
      background: $gradient-main;
      svg {
        fill: $white;
      }
    }

    &:disabled {
      color: $primary;
      background: $white;
      border: 1px solid $light-grey;
      opacity: 0.2;
      svg {
        fill: $primary;
      }
    }

    i {
      padding-right: 10px;
      svg {
        fill: $primary;
      }
    }
  }

  &.btn-disconnect {
    min-height: inherit;
    min-width: inherit;
    background: url(../icons/icon-disconnect.svg) no-repeat center;
    &:hover {
      background: url(../icons/icon-disconnect-white.svg) no-repeat center;
      background-color: $primary;
      background-size: 21px;
    }
    border-radius: 50% !important;
    height: 40px !important;
    width: 40px !important;
    background-size: 20px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }

  &.btn-edit-row {
    min-height: inherit;
    min-width: inherit;
    margin-left: 10px;
    margin-right: 10px;
    background: url(../icons/icon-edit-red.svg) no-repeat center;
    background-size: 16px;
    &:not(:disabled):hover {
      background: url(../icons/icon-edit.svg) no-repeat center;
      background-color: $primary;
      background-size: 18px;
    }
    border-radius: 10% !important;
    height: 20px !important;
    width: 20px !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
}

button[disabled] {
  opacity: 0.2;
  border-radius: 20px !important;
}

.modal {
  .btn {
    padding: 7px 12px 7px 12px;
    border-radius: 20px !important;
    min-width: 120px;
    border: none;
    font-size: $size-13 !important;
    color: $white;

    &.btn-main {
      color: $white;
      background-color: $primary;
      border-color: inherit !important;
      box-shadow: none !important;

      &:hover {
        background: $gradient-main;
        color: $white !important;
      }
      &:disabled {
        opacity: 1;
        background: none;
        background-color: $light-blue-grey;
      }
    }

    &.btn-white-main {
      text-decoration: none !important;
      color: $primary;
      background-color: $white;
      border: 1px solid $light-grey;
      &:hover {
        color: $primary !important;
      }
    }
  }
}
