@import 'assets/scss/_variables.scss';

$gap: 25px;

.optin-layout {
    display: flex;
    flex-direction: column;
    gap: $gap;
}

.optin-form-export {
    background-color: $light-blue-grey;
    border-radius: 20px;
    color: $clear-blue-grey;
    padding: 30px;
    width: 100%;
  
    .optin-form-export-container {
      position: relative;
      padding: 0rem 3rem 0rem 0rem;
      width: 100%;
    }
  }
