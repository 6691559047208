/**** Fonts ***/
$font-primary: "Poppins", sans-serif;

/***+ Colors ***/
//to use
$border-grey: #dde1e4 !default;
$clear-blue-grey: #6b7897 !default;
$light-blue-grey: #f8f9fa !default;
$light-grey: #efefef !default;
$clear-grey: #e1e3ea !default;
$clear-text: #bfc7ce !default;
$silver: #c4c4c4 !default;
$main-texts: #333 !default;
$primary: #f50a6b !default;
$white: #fff !default;
$secondary: #fed340 !default;
$green: #aeeacf !default;
$processing: #fcb922 !default;
$success: #7ed321 !default;
$error: #d0021b !default;

//to delete
$black: #000 !default;
$semi-light-grey: #777777 !default;
$grey: #939faf !default;

/****** Fonts size *****/
$size-10: 0.625rem; // 10px
$size-10: 0.688rem; // 11px
$size-12: 0.75rem; // 12px
$size-13: 0.8125rem; // 12px
$size-14: 0.875rem; // 14px
$size-15: 0.9375rem;
$size-16: 1rem; // 16px
$size-18: 1.125rem; // 18px
$size-20: 1.25rem; // 20px
$size-22: 1.375rem; // 22px
$size-24: 1.5rem; // 24px
$size-30: 1.875rem; // 30px
$size-35: 2.1875rem; // 35px

/****** Gradient *****/
$gradient-main: linear-gradient(0deg, rgba(245, 10, 107, 1) 0%, #fa8650 100%);

/***** Sidebar *******/
$sidebar-width: 250px;

/***** Header ********/
$header-height: 110px;

/***** Header ********/
$footer-height: 110px;

/***** Table  ********/
$row-selected: rgba(#2196f3, 0.05);

// Breakpoints
$screen-lg: 991px;
$screen-md: 768px;
$screen-sm: 641px;
