@import "assets/scss/_variables.scss";

.download-btn {
  width: 15;
  height: 15;
  fill: $black;
  cursor: pointer;
  &:hover,
  &:focus {
    fill: $primary;
  }
}
