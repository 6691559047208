@import "assets/scss/_variables.scss";

.custom-modal {
    border-radius: 5px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);

    .modal-message {
        font-size: $size-13;
        line-height: 1.375rem;
        margin: 0 0 15px;

        label {
            font-weight: normal;
            cursor: pointer;
        }
    }

    .btn {
        padding: 7px 12px 7px 12px;
        border-radius: 20px !important;
        min-width: 120px;
        border: none;
        font-size: $size-13 !important;
        color: $white;

        &.btn-cta {
            min-width: 140px;
            color: $primary;
            background: $white;
            border: 1px solid $primary;
            line-height: 33px;
            padding: 0 15px;
            text-decoration: none;

            &:hover {
                background: none;
                background-color: $primary !important;
                color: $white !important;
                svg {
                    fill: $white;
                }
            }
        }

        &.btn-main {
            color: $white;
            background-color: $primary;
            border-color: inherit !important;
            box-shadow: none !important;

            &:hover {
                background: $gradient-main;
                color: $white !important;
            }

            &:disabled {
                opacity: 1;
                background: none;
                background-color: $light-blue-grey;
            }
        }

        &.btn-white-main {
            text-decoration: none !important;
            color: $primary;
            background-color: $white;
            border: 1px solid $light-grey;
            &:hover {
                color: $primary !important;
            }
        }
    }
}
