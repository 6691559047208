@import "assets/scss/_variables.scss";

.date-picker {
  display: flex;
  flex-direction: column;

  input {
    min-width: 150px;
    padding: 6px;
    color: $clear-blue-grey;
    border: 1px solid #e1e3ea;
    border-radius: 4px;
    font-weight: 200;
    font-size: $size-14;

    &:focus-visible {
      outline: none;
      border: 1px solid $clear-blue-grey !important;
      box-shadow: 1px solid $clear-blue-grey !important;
    }
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: transparent url("../../assets/icons/icon-calendar-color.svg")
    no-repeat center;
  width: 20px;
  height: 20px;
  border-width: thin;
  cursor: pointer;
}
