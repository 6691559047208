@import "assets/scss/_variables.scss";

.tab-header {
    display: flex;
    position: sticky;
    top: 110px; // header-size
    z-index: 3;
    background-color: white;
    padding-bottom: 4px;

    .tab-container {
        display: flex;
        gap: 50px;

        .tab-button {
            text-decoration: none;
            font-size: $size-18;
            font-weight: 500;
            padding-bottom: 8px;
            color: $clear-blue-grey;
            box-shadow: 0px 4px 0px -2px rgba(107, 120, 151, 0.05);
            transition: color 0.3s ease, box-shadow 0.3s ease;

            &:hover,
            &:focus,
            &.active {
                font-weight: 500;
                color: $primary !important;
                box-shadow: 0px 4px 0px -2px $primary;
            }
            &.active {
                pointer-events: none;
                cursor: default;
            }
        }
    }
}
